/**
 * @name: 防伪溯源-溯源码管理接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 防伪溯源-溯源码管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get} from "@/request"
import {IPageRes} from "@/apis/page";
import type { ISource, ISourceParam } from "./types";

/**
 * 分页查询
 * @param param 查询参数
 * @returns
 */
export const traceabilityCodeManageByPageApi = (param: ISourceParam) => get<IPageRes<ISource[]>>("/admin/traceabilityCodeManage/query/byPage", param)
