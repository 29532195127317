
/**
 * @name: 防伪溯源-溯源码管理
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 防伪溯源-溯源码管理
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { traceabilityCodeManageByPageApi } from "@/apis/source/list"
import type { ISource, ISourceParam } from "@/apis/source/list/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from "@/utils/common";
import Qrcode from "qrcodejs2"

@Component({})
export default class sourceList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISource[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISourceParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: "销售订单编号",
        prop: "saleOrderSn",
        search: true,
        align: "center"
      },
      {
        label: "溯源二维码",
        prop: "id",
        align: "center",
        slot: true
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        type: "daterange",
        search: true,
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"]
      }
    ]
  }
  // 预览弹窗
  previewDialog = false
  // 预览url
  previewUrl = ''
  // 查看弹窗
  viewDialog = false

  getList () {
    this.tableLoading = true
    const query: ISourceParam = deepCopy(this.queryParam);
    if (this.queryParam.addTime && this.queryParam.addTime.length) {
      query.startTime = this.queryParam.addTime[0]
      query.endTime = this.queryParam.addTime[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.addTime
    traceabilityCodeManageByPageApi(query).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      setTimeout(() => {
        this.$nextTick(() => {
          for (let i = 0; i < e.list.length; i++) {
            const dom = document.querySelector(`#qrcode-${i}`)
            let url = ''
            let id = e.list[i]['id']
            if (process.env.NODE_ENV === 'development') {
              // 开发
              url = `${location.protocol}//${location.hostname}:81/h5/?id=${id}`
            } else {
              // 测试/生产
              url = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}/h5/?id=${id}`
            }
            dom!.innerHTML = '';
            new Qrcode(dom, {
              text: url,
              width: 70,
              height: 70
            })
          }
        })
      }, 0)
      this.tableLoading = false
    })
  }

  openPreview (id: string) {
    let url = ''
    if (process.env.NODE_ENV === 'development') {
      // 开发
      url = `${location.protocol}//${location.hostname}:81/h5/?id=${id}`
    } else {
      // 测试/生产
      url = `/h5/?id=${id}`
    }
    this.previewUrl = url
    this.previewDialog = true
  }

  openView (id: string) {
    this.viewDialog = true
    setTimeout(() => {
      this.$nextTick(() => {
        const dom = document.querySelector(`#viewcode`)
        dom!.innerHTML = '';
        let url = ''
        if (process.env.NODE_ENV === 'development') {
          // 开发
          url = `${location.protocol}//${location.hostname}:81/h5/?id=${id}`
        } else {
          // 测试/生产
          url = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}/h5/?id=${id}`
        }
        new Qrcode(dom, {
          text: url,
          width: 120,
          height: 120
        })
      })
    }, 0)
  }

  created () {
    this.getList()
  }
}
